import CssBaseline from "@material-ui/core/CssBaseline";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import {ThemeProvider} from "@material-ui/styles";
import * as PropTypes from 'prop-types'
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from "react-router-dom";
import App from "./App";
import {createTheme} from "@material-ui/core";

let theme = createTheme({
  palette: {
    primary: {
      main: '#49a3a8'
    },
    secondary: {
      main: '#e49d3b'
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 16
  }
});
theme = responsiveFontSizes(theme);

const Root = props => {
  const {store} = props;

  return (
      <Provider store={store}>
        <BrowserRouter>
          <CssBaseline/>
          <ThemeProvider theme={theme}>
            <App/>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
  );
}

export default Root;

Root.propTypes = {
  store: PropTypes.object.isRequired
};
